import request from '@/utils/request'; // 获取小黑裙信息列表

export function managerList(data) {
  return request({
    url: '/manager/vipcustomer/vipcustomerGet',
    method: 'post',
    data: data
  });
} // 添加小黑裙用户

export function addManager(data) {
  return request({
    url: '/manager/vipcustomer/vipcustomerAdd',
    method: 'post',
    data: data
  });
} // 添加小黑裙用户信息

export function addManagerCall(data) {
  return request({
    url: '/manager/vipcustomer/vipcustomerAddcall',
    method: 'post',
    data: data
  });
} // 客户资料保存

export function ManagerSave(data) {
  return request({
    url: '/manager/vipcustomer/vipcustomerUpd',
    method: 'post',
    data: data
  });
} // 消费情况列表

export function GetVipCustomer(query) {
  return request({
    url: '/manager/vipconsume/vipconsumeGet',
    method: 'post',
    params: query
  });
} // 添加消费情况列表

export function VipConsumeAdd(data) {
  return request({
    url: '/manager/vipconsume/vipconsumeAdd',
    method: 'post',
    data: data
  });
} // 删除消费情况列表

export function VipConsumeDel(data) {
  return request({
    url: '/manager/vipconsume/vipconsumeDel',
    method: 'post',
    data: data
  });
} // 修改消费情况列表

export function VipConsumeUpd(data) {
  return request({
    url: '/manager/vipconsume/vipconsumeUpd',
    method: 'post',
    data: data
  });
} // 接待记录列表

export function VipReceiveGet(query) {
  return request({
    url: '/manager/receive/vipreceiveGet',
    method: 'post',
    params: query
  });
} // 添加接待记录

export function VipReceiveAdd(data) {
  return request({
    url: '/manager/receive/vipreceiveAdd',
    method: 'post',
    data: data
  });
} // 修改接待记录

export function VipReceiveUpd(data) {
  return request({
    url: '/manager/receive/vipreceiveUpd',
    method: 'post',
    data: data
  });
} // 删除接待记录

export function VipReceiveDel(data) {
  return request({
    url: '/manager/receive/vipreceiveDel',
    method: 'post',
    data: data
  });
} // 意见列表

export function VipAdviceGet(query) {
  return request({
    url: '/manager/vipadvice/vipadviceGet',
    method: 'post',
    params: query
  });
} // 添加意见

export function VipAdviceAdd(data) {
  return request({
    url: '/manager/vipadvice/vipadviceAdd',
    method: 'post',
    data: data
  });
} // 修改意见

export function VipAdviceUpd(data) {
  return request({
    url: '/manager/vipadvice/vipadviceUpd',
    method: 'post',
    data: data
  });
} // 删除意见

export function VipAdviceDel(data) {
  return request({
    url: '/manager/vipadvice/vipadviceDel',
    method: 'post',
    data: data
  });
}
export function payRanking(data) {
  return request({
    url: '/manager/ctmPay/payRanking',
    method: 'post',
    data: data
  });
} // 客户交易汇总表

export function payCtmCollect(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollect',
    method: 'post',
    data: data
  });
} // 客户状态等级修改

export function changeTypeOrLevel(data) {
  return request({
    url: '/manager/customer/changeTypeOrLevel',
    method: 'post',
    data: data
  });
} // 可手动标记-等级

export function listManual() {
  return request({
    url: '/manager/customerLevel/listManual',
    method: 'get'
  });
} // 改派

export function reassign(data) {
  return request({
    url: '/manager/customer/reassign',
    method: 'POST',
    data: data
  });
} // 改派客户列表

export function listForReassign(data) {
  return request({
    url: '/manager/customer/listForReassign',
    method: 'POST',
    data: data
  });
} // 汇总适应症

export function payCtmCollectSymptom(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectSymptom',
    method: 'POST',
    data: data
  });
} // 汇总适应症-无项目

export function payCtmCollectSymptomType(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectSymptomType',
    method: 'POST',
    data: data
  });
} // 汇总适应症-总计

export function payCtmCollectSymptomTypeTotal(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectSymptomTypeTotal',
    method: 'POST',
    data: data
  });
} // 汇总项目-总计

export function payCtmCollectPtypeTotal(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectPtypeTotal',
    method: 'POST',
    data: data
  });
} // 汇总适应症-导出

export function payCtmCollectSymptomExport(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectSymptomExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 汇总适应症-导出

export function payCtmCollectPtypeTotalExport(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectPtypeTotalExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 汇总适应症-导出

export function payCtmCollectSymptomTypeTotalExport(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectSymptomTypeTotalExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 汇总适应症-无项目导出

export function payCtmCollectSymptomTypeExport(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectSymptomTypeExport',
    method: 'POST',
    data: data,
    responseType: 'blob'
  });
} // 系列汇总-无项目

export function payCtmCollectPtype(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectPtype',
    method: 'POST',
    data: data
  });
} // 系列汇总-无项目导出

export function payCtmCollectPtypeExport(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectPtypeExport',
    method: 'POST',
    data: data,
    responseType: 'blob'
  });
}